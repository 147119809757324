/// <reference path="../all.d.ts" />

import React from "react"
import { Helmet } from "react-helmet"
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import { withPrefix, useStaticQuery, graphql } from "gatsby"
import { getLocalStorage, setLocalStorage } from "../utils"
import { CART } from "../constants"
import { CartItem } from "../types"
import Navbar from "./Navbar"
import "../stylesheets/index.sass"
import LayoutSnackbar from "./LayoutSnackbar"
import Footer from "./Footer"
import { indigo, orange } from "@material-ui/core/colors"
import kanit from "../../static/fonts/kanit-700.woff2"
import rubik from "../../static/fonts/rubik.woff2"

interface LayoutProps {
  pageTitle: string
  hideNavbar?: boolean
  cartProps?: boolean
  hideFooter?: boolean
  ogImageURL?: string
  ogDescription?: string
  pathname: string
  landingPage?: boolean
}

const theme = createMuiTheme({
  palette: {
    primary: indigo,
    secondary: orange,
  },
  typography: {
    fontFamily: [
      '"Rubik"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
})

const Layout: React.FC<LayoutProps> = ({
  pageTitle,
  children,
  cartProps,
  hideNavbar,
  hideFooter,
  ogDescription,
  ogImageURL,
  pathname,
  landingPage,
}) => {
  const initCartItems = getLocalStorage(CART) || []
  const [cartItems, setCartItems] = React.useState<CartItem[]>(initCartItems)
  const [cartOpen, setCartOpen] = React.useState(false)
  const [snackbarMessage, setSnackbarMessage] = React.useState("")

  function updateCart(updatedCartItems: CartItem[]) {
    setLocalStorage(CART, updatedCartItems)
    setCartItems(updatedCartItems)
  }
  function addToCart(cartItem: CartItem) {
    const updatedCartItems = [...cartItems, cartItem]
    updateCart(updatedCartItems)
    setSnackbarMessage("Item added to cart")
    setCartOpen(true)
  }
  function updateCartItem(cartItem: CartItem, index: number) {
    const updatedCartItems = [...cartItems]
    updatedCartItems[index] = cartItem
    updateCart(updatedCartItems)
  }
  function removeCartItem(index: number) {
    const updatedCartItems = [...cartItems]
    updatedCartItems.splice(index, 1)
    updateCart(updatedCartItems)
  }
  const {
    site: {
      siteMetadata: { title, description, author },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  const wrappedChidren = cartProps
    ? React.cloneElement(children as React.ReactElement<any>, { addToCart })
    : children
  const ogTitle = `Dzign Studio - ${pageTitle}`
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>{ogTitle}</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}favicon-16x16.png`}
          sizes="16x16"
        />
        <link
          rel="mask-icon"
          href={`${withPrefix("/")}safari-pinned-tab.svg`}
          color="#2e7d32"
        />
        <link
          rel="preload"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
          href={kanit}
        />
        <link
          rel="preload"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
          href={rubik}
        />
        <meta name="theme-color" content="#ffffff" />
        <meta name="description" content={ogDescription || description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={pathname} />
        <meta
          property="og:description"
          content={ogDescription || description}
        />
        <meta property="og:title" content={ogTitle} />
        <meta
          property="og:image"
          content={ogImageURL || `${withPrefix("/")}og-image.jpg`}
        />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:creator" content={author} />
        <meta property="twitter:title" content={ogTitle} />
        <meta
          property="twitter:description"
          content={ogDescription || description}
        />
      </Helmet>
      <noscript>
        JavaScript is currently disabled in your browser. Most features of this
        website require JavaScript to work properly, so please enable JavaScript
        in your browser for full site functionality.
      </noscript>
      {!hideNavbar && (
        <Navbar
          cartItems={cartItems}
          cartOpen={cartOpen}
          setCartOpen={setCartOpen}
          updateCartItem={updateCartItem}
          removeCartItem={removeCartItem}
          landingPage={landingPage}
        />
      )}
      <ThemeProvider theme={theme}>
        <main
          className={`${hideNavbar || landingPage ? "" : "add-navbar-padding"}`}
        >
          {wrappedChidren}
        </main>
      </ThemeProvider>
      {!hideFooter && <Footer />}
      <LayoutSnackbar
        snackbarMessage={snackbarMessage}
        setSnackbarMessage={setSnackbarMessage}
      />
    </>
  )
}

export default Layout
