import React from "react"
import { Link } from "gatsby"
import Logo from "./Logo"
import SocialButtons from "./SocialButtons"
import {
  ABOUT_US_ROUTE,
  CONTACT_US_ROUTE,
  CUSTOM_ORDER_REQUEST_ROUTE,
} from "../constants"
import WavefoundryLogo from "./WavefoundryLogo"

export default () => {
  return (
    <footer>
      <div className="layout-container">
        <div className="footer-container">
          <div className="footer-link-grid">
            <div>
              <Link to="/" className="footer-link logo-container">
                <Logo />
              </Link>
            </div>
            <div className="footer-link-container">
              <Link to={CONTACT_US_ROUTE} className="footer-link">
                Contact Us
              </Link>
            </div>
            <div className="footer-link-container">
              <Link to={ABOUT_US_ROUTE} className="footer-link">
                About Us
              </Link>
            </div>
            <div className="footer-link-container">
              <Link to={CUSTOM_ORDER_REQUEST_ROUTE} className="footer-link">
                Custom Order Request
              </Link>
            </div>
          </div>
          <SocialButtons />
        </div>
        <div className="footer-captions">
          <div className="legal">
            <span>© Copyright {new Date().getFullYear()} Dzign Studio</span>
          </div>
          <div>
            <a
              href="https://wavefoundry.io"
              target="_blank"
              rel="noopener noreferrer"
              className="wf-link"
            >
              <div className="logo">
                <WavefoundryLogo />
              </div>
              <div className="text">Powered by Wavefoundry</div>
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}
