import { CartItem, Coupon } from "../types"

export function convertCentsToCurrency(cents: number) {
  return `${(cents / 100).toFixed(2)}`
}
const isBrowser = () => typeof window !== "undefined"
export const getLocalStorage = (itemName: string) => {
  if (isBrowser()) {
    const item = window.localStorage.getItem(itemName)
    return item ? JSON.parse(item) : null
  }
  return null
}
export const setLocalStorage = (itemName: string, item: any) => {
  window.localStorage.setItem(itemName, JSON.stringify(item))
}
export const removeLocalStorage = (itemName: string) => {
  window.localStorage.removeItem(itemName)
}
export const getSubtotal = (cartItems: CartItem[]) => {
  let subtotal = 0
  for (const { price, quantity } of cartItems) {
    subtotal += price * quantity
  }
  return subtotal
}
export const getCheckoutSummaryPrices = (
  cartItems: CartItem[],
  couponData: Coupon,
  shippingTotal = 0
) => {
  let subtotal = getSubtotal(cartItems)
  let orderTotal = subtotal
  if (couponData) {
    orderTotal -= couponData.discount
  }
  orderTotal += shippingTotal
  return {
    subtotal,
    shippingTotal,
    couponDiscount: couponData ? couponData.discount : null,
    orderTotal,
  }
}
