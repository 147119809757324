import React from "react"
import Drawer from "@material-ui/core/Drawer"
import Icon from "./Icon"
import { Link, graphql, useStaticQuery } from "gatsby"
import Logo from "./Logo"
import SocialButtons from "./SocialButtons"
import {
  ABOUT_US_ROUTE,
  CONTACT_US_ROUTE,
  CUSTOM_ORDER_REQUEST_ROUTE,
  HOME_ROUTE,
  SEASONAL_ROUTE,
  WEDDING_ROUTE,
} from "../constants"

export default () => {
  const pageData = useStaticQuery(graphql`
    query {
      pageContent {
        seasonalPageTitle
        enableSeasonalProducts
      }
    }
  `)
  const [open, setOpen] = React.useState(false)
  function handleOpen() {
    setOpen(true)
  }
  function handleClose() {
    setOpen(false)
  }
  const menuItems = [
    {
      label: "Weddings",
      path: WEDDING_ROUTE,
    },
    {
      label: "Home Decor",
      path: HOME_ROUTE,
    },
    {
      label: "About Us",
      path: ABOUT_US_ROUTE,
    },
    {
      label: "Contact Us",
      path: CONTACT_US_ROUTE,
    },
  ]
  if (menuItems.length === 4) {
    if (pageData.pageContent.enableSeasonalProducts) {
      menuItems.splice(2, 0, {
        label: pageData.pageContent.seasonalPageTitle,
        path: SEASONAL_ROUTE,
      })
    } else {
      menuItems.push({
        label: "Custom Order Request",
        path: CUSTOM_ORDER_REQUEST_ROUTE,
      })
    }
  }
  return (
    <>
      <button
        className="btn nav-btn menu-btn flex-justify-center"
        onClick={handleOpen}
      >
        <Icon iconName="menuBars" width={26} />
      </button>
      <Drawer open={open} onClose={handleClose} anchor="right">
        <div className="menu-drawer">
          <div className="menu-items-container">
            <div className="padding-bottom-1">
              <div className="drawer-header flex-justify-center">
                <Link className="logo-container" to="/" title="Dzign Studio">
                  <Logo />
                </Link>
              </div>
              <ul className="menu-item-list">
                {menuItems.map(({ path, label }) => {
                  return (
                    <li key={path}>
                      <Link to={path} className="menu-drawer-link">
                        {label}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className="padding-2">
              <SocialButtons />
            </div>
          </div>
        </div>
      </Drawer>
    </>
  )
}
