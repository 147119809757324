import React from "react"

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 750 750"
      width="200px"
    >
      <defs>
        <path
          d="M738.21 506.39C703.82 615.1 627.21 685.44 533.26 717.95C439.32 750.46 341.41 735.52 239.72 698.75C138.02 661.45 32.55 602.31 2.52 506.39C-27.5 409.93 63.06 324.31 140.05 219.86C217.53 114.88 272.02 21.61 383.4 14.68C495.26 8.29 582.97 58.84 654.63 162.23C726.3 265.61 773.07 397.68 738.21 506.39Z"
          id="e6NSNSLlNG"
        />
        <path
          d="M572.7 292.27C599.49 265.48 572.59 248.93 545.8 222.15C509.52 258.43 219.3 548.65 183.02 584.92C197.63 599.09 213.58 611.87 230.67 623.06C248.4 601.78 390.22 431.5 407.95 410.22C428.86 417.31 451.3 413.68 468.9 396.07C489.66 375.31 562.32 302.65 572.7 292.27Z"
          id="a1Z5j9WYUi"
        />
        <path
          d="M545.8 222.16C519.02 195.37 502.47 168.48 475.69 195.27C465.31 205.65 382.26 288.69 371.88 299.07C354.29 316.66 350.65 339.09 357.74 360.02C336.25 377.79 164.35 519.91 142.86 537.68C154.7 554.71 168.17 570.52 183.02 584.93C255.58 512.37 509.52 258.44 545.8 222.16Z"
          id="aOoCVNLOj"
        />
        <path
          d="M475.68 195.26C465.3 205.64 382.26 288.69 371.88 299.07C359.24 311.71 456.24 408.73 468.9 396.07C479.28 385.69 562.32 302.65 572.7 292.27C599.49 265.48 576.17 245.35 549.39 218.57C522.6 191.78 502.47 168.47 475.68 195.26Z"
          id="b6fTOp33"
        />
        <path
          d="M475.68 195.26C465.3 205.64 382.26 288.69 371.88 299.07C365.56 305.39 386.65 332.8 410.9 357.05C420.13 347.82 466.29 301.66 549.39 218.57C518.11 185.17 493.54 177.4 475.68 195.26Z"
          id="a2yQ9UBR01"
        />
        <path
          d="M354.32 350.9C348.77 356.57 304.33 401.92 298.77 407.58C291.94 414.41 353.81 475.76 360.65 468.92C366.26 463.31 411.11 418.46 416.72 412.85C416.71 412.85 396.16 408.93 378.39 391.16C360.63 373.4 359.13 358.95 354.32 350.9Z"
          id="ggQzc6WzG"
        />
        <path
          d="M354.32 350.9C348.77 356.57 304.33 401.91 298.77 407.58C295.35 411 308.24 427.68 324.32 443.77C327.86 440.2 345.56 422.34 377.43 390.21C365.1 369.24 357.4 356.14 354.32 350.9Z"
          id="c14c6giMBz"
        />
        <path
          d="M414.91 256.03C402.27 268.67 499.28 365.69 511.93 353.04C524 344.35 620.61 274.85 632.68 266.17C659.45 239.4 528.57 108.51 501.78 135.29C484.4 159.43 423.6 243.96 414.91 256.03Z"
          id="er1efPC8D"
        />
        <path
          d="M414.91 256.03C408.69 262.25 428.99 288.87 452.74 312.83C466.2 299.61 573.87 193.88 587.33 180.67C554.6 147.93 515.18 121.9 501.78 135.29C484.4 159.43 423.6 243.96 414.91 256.03Z"
          id="g1n6CwOE7l"
        />
        <path
          d="M437.57 272.76C435.37 271.08 434.96 267.93 436.64 265.73C445.31 254.43 514.63 164.05 523.3 152.75C524.98 150.55 528.13 150.14 530.32 151.83C532.52 153.51 532.93 156.66 531.25 158.86C522.58 170.16 453.26 260.54 444.59 271.83C443.61 273.12 442.12 273.8 440.62 273.8C439.55 273.8 438.47 273.46 437.57 272.76Z"
          id="c1Lihg0go6"
        />
        <path
          d="M497 332.19C495.31 329.99 495.72 326.84 497.92 325.15C509.22 316.49 599.59 247.16 610.89 238.5C613.09 236.81 616.24 237.23 617.92 239.43C619.6 241.62 619.19 244.77 616.99 246.46C605.7 255.12 515.32 324.45 504.03 333.11C503.12 333.81 502.04 334.15 500.97 334.15C499.47 334.15 497.98 333.47 497 332.19Z"
          id="ddozeS8lR"
        />
        <path
          d="M476.95 312.2C475.08 310.15 475.22 306.98 477.27 305.11C488.21 295.13 575.71 215.28 586.65 205.3C588.7 203.43 591.87 203.58 593.73 205.63C595.6 207.68 595.46 210.84 593.41 212.72C582.47 222.7 494.97 302.54 484.03 312.52C483.07 313.4 481.86 313.83 480.65 313.83C479.29 313.83 477.93 313.28 476.95 312.2Z"
          id="b1cU5wGWBx"
        />
        <path
          d="M456.36 291.61C454.32 289.74 454.17 286.57 456.04 284.52C466.02 273.58 545.86 186.07 555.84 175.14C557.71 173.09 560.89 172.94 562.93 174.81C564.97 176.68 565.12 179.85 563.25 181.9C553.27 192.84 473.42 280.34 463.44 291.28C462.46 292.37 461.11 292.92 459.74 292.92C458.54 292.92 457.33 292.48 456.36 291.61Z"
          id="nGkFDQxDk"
        />
      </defs>
      <g>
        <g>
          <g>
            <use
              xlinkHref="#e6NSNSLlNG"
              opacity="1"
              fill="#f3943d"
              fillOpacity="0.5"
            />
            <g>
              <use
                xlinkHref="#e6NSNSLlNG"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              />
            </g>
          </g>
          <g>
            <use
              xlinkHref="#a1Z5j9WYUi"
              opacity="1"
              fill="#bd5731"
              fillOpacity="1"
            />
            <g>
              <use
                xlinkHref="#a1Z5j9WYUi"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              />
            </g>
          </g>
          <g>
            <use
              xlinkHref="#aOoCVNLOj"
              opacity="1"
              fill="#aa4925"
              fillOpacity="1"
            />
            <g>
              <use
                xlinkHref="#aOoCVNLOj"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              />
            </g>
          </g>
          <g>
            <use
              xlinkHref="#b6fTOp33"
              opacity="1"
              fill="#cdcccb"
              fillOpacity="1"
            />
            <g>
              <use
                xlinkHref="#b6fTOp33"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              />
            </g>
          </g>
          <g>
            <use
              xlinkHref="#a2yQ9UBR01"
              opacity="1"
              fill="#aaaaaa"
              fillOpacity="1"
            />
            <g>
              <use
                xlinkHref="#a2yQ9UBR01"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              />
            </g>
          </g>
          <g>
            <use
              xlinkHref="#ggQzc6WzG"
              opacity="1"
              fill="#b2b2b2"
              fillOpacity="1"
            />
            <g>
              <use
                xlinkHref="#ggQzc6WzG"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              />
            </g>
          </g>
          <g>
            <use
              xlinkHref="#c14c6giMBz"
              opacity="1"
              fill="#8d9091"
              fillOpacity="1"
            />
            <g>
              <use
                xlinkHref="#c14c6giMBz"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              />
            </g>
          </g>
          <g>
            <use
              xlinkHref="#er1efPC8D"
              opacity="1"
              fill="#3d4040"
              fillOpacity="1"
            />
            <g>
              <use
                xlinkHref="#er1efPC8D"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              />
            </g>
          </g>
          <g>
            <use
              xlinkHref="#g1n6CwOE7l"
              opacity="1"
              fill="#343433"
              fillOpacity="1"
            />
            <g>
              <use
                xlinkHref="#g1n6CwOE7l"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              />
            </g>
          </g>
          <g>
            <use
              xlinkHref="#c1Lihg0go6"
              opacity="1"
              fill="#1d1e1e"
              fillOpacity="1"
            />
            <g>
              <use
                xlinkHref="#c1Lihg0go6"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              />
            </g>
          </g>
          <g>
            <use
              xlinkHref="#ddozeS8lR"
              opacity="1"
              fill="#2e2f2f"
              fillOpacity="1"
            />
            <g>
              <use
                xlinkHref="#ddozeS8lR"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              />
            </g>
          </g>
          <g>
            <use
              xlinkHref="#b1cU5wGWBx"
              opacity="1"
              fill="#2e2f2f"
              fillOpacity="1"
            />
            <g>
              <use
                xlinkHref="#b1cU5wGWBx"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              />
            </g>
          </g>
          <g>
            <use
              xlinkHref="#nGkFDQxDk"
              opacity="1"
              fill="#1d1e1e"
              fillOpacity="1"
            />
            <g>
              <use
                xlinkHref="#nGkFDQxDk"
                opacity="1"
                fillOpacity="0"
                stroke="#000000"
                strokeWidth="1"
                strokeOpacity="0"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
