import React from "react"
import { Link } from "gatsby"
import Drawer from "@material-ui/core/Drawer"
import Icon from "./Icon"
import { CartItem } from "../types"
import CartItemContainer from "./CartItemContainer"
import CartPlaceholderSVG from "./CartPlaceholderSVG"
import { convertCentsToCurrency, getCheckoutSummaryPrices } from "../utils"

interface CartProps {
  cartItems: CartItem[]
  cartOpen: boolean
  setCartOpen: (open: boolean) => void
  updateCartItem: (cartItem: CartItem, index: number) => void
  removeCartItem: (index: number) => void
}
const Cart: React.FC<CartProps> = ({
  cartItems,
  cartOpen,
  setCartOpen,
  updateCartItem,
  removeCartItem,
}) => {
  const [isClient, setIsClient] = React.useState(false)
  React.useEffect(() => {
    setIsClient(true)
  }, [])
  const count = cartItems.length
  function handleClose() {
    setCartOpen(false)
  }
  function handleOpen() {
    setCartOpen(true)
  }
  const { subtotal } = getCheckoutSummaryPrices(cartItems, null, 0)
  function renderCartItems() {
    if (cartItems.length > 0) {
      return (
        <>
          <div>
            {cartItems.map((cartItem, index) => {
              function setCartItem(cartItemData: CartItem) {
                updateCartItem(cartItemData, index)
              }
              function handleRemove() {
                removeCartItem(index)
              }
              return (
                <CartItemContainer
                  cartItem={cartItem}
                  key={`cart-item-${index}`}
                  setCartItem={setCartItem}
                  handleRemove={handleRemove}
                />
              )
            })}
          </div>
          <div className="checkout-summary-table">
            <div className="checkout-summary-row">
              <div>
                <strong>Order Subtotal</strong>
              </div>
              <div>
                <strong>${convertCentsToCurrency(subtotal)}</strong>
              </div>
            </div>
            <div className="checkout-small margin-bottom-2 text-muted">
              <p>* Shipping will be calculated at checkout</p>
            </div>
          </div>
          <Link
            className="btn rounded-btn primary-btn full-width"
            to="/checkout/"
          >
            <strong>{`CHECKOUT`}</strong>
          </Link>
        </>
      )
    }
    return (
      <div className="cart-placeholder flex-justify-center">
        <CartPlaceholderSVG />
        <p>No items in your cart yet!</p>
      </div>
    )
  }
  if (isClient) {
    return (
      <>
        <button
          className={`btn nav-btn cart-btn flex-center ${
            count > 0 ? " has-items" : ""
          }`}
          onClick={handleOpen}
        >
          <div className="cart">
            <Icon iconName="cart" width={22} />
          </div>
          <div className="count">{count > 9 ? "9+" : count}</div>
        </button>
        <Drawer open={cartOpen} anchor="right" onClose={handleClose}>
          <div className="cart-drawer">
            <header className="flex-justify-center drawer-header">
              <h5>Your Cart</h5>
              <button className="close-cart-btn" onClick={handleClose}>
                <Icon iconName="close" width={24} />
              </button>
            </header>
            <div className="padding-2">{renderCartItems()}</div>
          </div>
        </Drawer>
      </>
    )
  }
  return null
}

export default Cart
