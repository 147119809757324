import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Logo from "./Logo"
import Cart from "./Cart"
import { CartItem } from "../types"
import MenuDrawer from "./MenuDrawer"
import { HOME_ROUTE, SEASONAL_ROUTE, WEDDING_ROUTE } from "../constants"
import SearchBar from "./SearchBar"
import SearchDialog from "./SearchDialog"

interface NavbarProps {
  cartItems: CartItem[]
  cartOpen: boolean
  setCartOpen: (open: boolean) => void
  updateCartItem: (cartItem: CartItem, index: number) => void
  removeCartItem: (index: number) => void
  landingPage?: boolean
}

const Navbar: React.FC<NavbarProps> = ({
  cartItems,
  cartOpen,
  setCartOpen,
  updateCartItem,
  removeCartItem,
  landingPage,
}) => {
  const pageData = useStaticQuery(graphql`
    query {
      pageContent {
        enableSeasonalProducts
        seasonalPageTitle
      }
    }
  `)
  return (
    <nav className={landingPage ? "landing-page" : ""}>
      <div className="layout-container">
        <div className="navbar-container">
          <div className="flex-center">
            <Link className="logo-container" to="/" title="Dzign Studio">
              <Logo />
            </Link>
            <Link
              to={WEDDING_ROUTE}
              className="navbar-link"
              activeClassName="active"
            >
              Weddings
            </Link>
            <Link
              to={HOME_ROUTE}
              className="navbar-link"
              activeClassName="active"
            >
              Home Decor
            </Link>
            {pageData.pageContent.enableSeasonalProducts && (
              <Link
                to={SEASONAL_ROUTE}
                className="navbar-link"
                activeClassName="active"
              >
                {pageData.pageContent.seasonalPageTitle}
              </Link>
            )}
          </div>
          <div className="flex-center">
            <div className="searchbar-container">
              <SearchBar isNavbar />
            </div>
            <SearchDialog />
            <Cart
              cartItems={cartItems}
              cartOpen={cartOpen}
              setCartOpen={setCartOpen}
              updateCartItem={updateCartItem}
              removeCartItem={removeCartItem}
            />
            <MenuDrawer />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
