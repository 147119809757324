import React from "react"
import Dialog from "@material-ui/core/Dialog"
import GatsbyImage from "gatsby-image"
import Icon from "./Icon"
import { CartItem } from "../types"
import { convertCentsToCurrency } from "../utils"

const PersonalizationDialog: React.FC<{ content: string }> = ({ content }) => {
  const [open, setOpen] = React.useState(false)
  function handleOpen() {
    setOpen(true)
  }
  function handleClose() {
    setOpen(false)
  }
  return (
    <>
      <button
        className="btn view-details-btn"
        onClick={handleOpen}
        type="button"
      >
        View Personalization Details
      </button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <div className="padding-2 position-relative">
          <button
            className="btn close-dialog-btn flex-justify-center"
            onClick={handleClose}
            type="button"
          >
            <Icon iconName="close" width={20} />
          </button>
          <h6>Personalization Details</h6>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </Dialog>
    </>
  )
}

interface CartItemContainerProps {
  cartItem: CartItem
  setCartItem: (cartItem: CartItem) => void
  handleRemove: () => void | null
}
const QUANTITY_MAX = 9
const CartItemContainer: React.FC<CartItemContainerProps> = ({
  cartItem,
  setCartItem,
  handleRemove,
}) => {
  function increment() {
    if (cartItem.quantity <= QUANTITY_MAX) {
      setCartItem({ ...cartItem, quantity: cartItem.quantity + 1 })
    }
  }
  function decrement() {
    if (cartItem.quantity > 1) {
      setCartItem({ ...cartItem, quantity: cartItem.quantity - 1 })
    }
  }
  return (
    <div className="cart-item">
      {handleRemove && (
        <button
          className={`btn delete-btn flex-justify-center`}
          onClick={handleRemove}
          title="Remove this item"
          type="button"
        >
          <Icon iconName="delete" width={14} />
        </button>
      )}
      <div className="cart-header">
        <div className="cart-header-image">
          <GatsbyImage fluid={cartItem.productImage.childImageSharp.fluid} />
        </div>
        <div className="cart-header-text">
          <h6>{cartItem.name}</h6>
          {cartItem.multiplePriceOptions && (
            <p className="text-muted">{cartItem.priceOption}</p>
          )}
          {cartItem.stainColor && (
            <p>
              <strong>Stain Color:</strong> {cartItem.stainColor}
            </p>
          )}
          {cartItem.vinylColor && (
            <p>
              <strong>Vinyl Color:</strong> {cartItem.vinylColor}
            </p>
          )}
          {cartItem.styleOption && (
            <p>
              <strong>{cartItem.styleOptionLabel}:</strong>{" "}
              {cartItem.styleOption}
            </p>
          )}
          {cartItem.personalization && (
            <>
              {cartItem.richTextPersonalization ? (
                <PersonalizationDialog content={cartItem.personalization} />
              ) : (
                <p>
                  <strong>Personalization:</strong> {cartItem.personalization}
                </p>
              )}
            </>
          )}
          {cartItem.imagePath && (
            <div className="flex-center">
              <div className="image-uploaded-icon flex-center">
                <Icon iconName="checkCircle" width={16} />
              </div>
              <div>
                <p className="text-muted">Image Uploaded</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="qty-container">
        <div className="qty-controls">
          {!cartItem.disableQuantityUpdate && (
            <>
              <div>
                <button
                  className={`btn flex-justify-center ${
                    cartItem.quantity === 1 ? " disabled" : ""
                  }`}
                  onClick={decrement}
                  type="button"
                >
                  <Icon iconName="minus" width={16} />
                </button>
              </div>
              <div className="count">{cartItem.quantity}</div>
              <div>
                <button
                  className={`btn flex-justify-center ${
                    cartItem.quantity === QUANTITY_MAX ? " disabled" : ""
                  }`}
                  onClick={increment}
                  type="button"
                >
                  <Icon iconName="plus" width={16} />
                </button>
              </div>
            </>
          )}
        </div>
        <div>
          <p className="price">
            <strong>
              ${convertCentsToCurrency(cartItem.price * cartItem.quantity)}
            </strong>
            {cartItem.quantity > 1 && (
              <span className="hint">
                (${convertCentsToCurrency(cartItem.price)} ea.)
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  )
}

export default CartItemContainer
