import React from "react"
import { EMAIL } from "../constants"
import Icon from "./Icon"

export default () => {
  return (
    <div className="social-btns-container flex-justify-center">
      <div>
        <a
          className="social-btn"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/DzignStudio-102320024905585"
          title="Follow us on Facebook"
        >
          <Icon width={24} iconName="facebook" />
        </a>
      </div>
      <div>
        <a
          className="social-btn"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/dzignstudio/"
          title="Follow us on Instagram"
        >
          <Icon width={24} iconName="instagram" />
        </a>
      </div>
      <div>
        <a
          className="social-btn"
          href={`mailto:${EMAIL}`}
          title={`Email us at ${EMAIL}`}
        >
          <Icon width={24} iconName="email" />
        </a>
      </div>
    </div>
  )
}
