import React from "react"
import { Link, useStaticQuery, graphql, navigate } from "gatsby"
import Grow from "@material-ui/core/Grow"
import Icon from "./Icon"
import {
  HOME_ROUTE,
  SEARCH_ROUTE,
  SEASONAL_ROUTE,
  WEDDING_ROUTE,
} from "../constants"

interface SearchbarProps {
  isNavbar?: boolean
  handleClose?: () => void
}
const SearchBar: React.FC<SearchbarProps> = ({ isNavbar, handleClose }) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [searchValue, setSearchValue] = React.useState("")
  const [focused, setFocused] = React.useState(false)
  function handleFocus() {
    setFocused(true)
  }
  function handleBlur() {
    setFocused(false)
  }
  const results = useStaticQuery(graphql`
    query {
      allProduct(sort: { fields: featured, order: DESC }) {
        nodes {
          name
          path
          subcategory
        }
      }
      pageContent {
        enableSeasonalProducts
        seasonalPageTitle
      }
    }
  `)
  let products = results.allProduct.nodes
  const subcategories = []
  const subcategorySet = new Set()
  subcategorySet.add("Seasonal") // We already add the seasonal route below, so we don't need to add it twice
  products.forEach(({ subcategory }) => {
    if (!subcategorySet.has(subcategory)) {
      subcategories.push({
        name: subcategory,
        path: `${SEARCH_ROUTE}?q=${encodeURI(subcategory)}`,
      })
      subcategorySet.add(subcategory)
    }
  })
  let categories = [
    { name: "All Wedding Decor", path: WEDDING_ROUTE },
    { name: "All Home Decor", path: HOME_ROUTE },
  ]
  if (results.pageContent.enableSeasonalProducts) {
    categories.push({
      name: results.pageContent.seasonalPageTitle,
      path: SEASONAL_ROUTE,
    })
  }
  categories = [...categories, ...subcategories]

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchValue(event.target.value)
  }
  if (searchValue) {
    const regEx = new RegExp(
      searchValue.replace(/[|&;$%@"<>()+,\\]/g, match => {
        return `\\${match}`
      }),
      "i"
    )
    products = products.filter(({ name }) => regEx.test(name))
    categories = categories.filter(({ name }) => regEx.test(name))
  }
  function renderSearchResults() {
    if (products.length === 0 && categories.length === 0) {
      return (
        <div className="padding-2 text-muted">No search results to show</div>
      )
    }
    return (
      <>
        {categories.length > 0 && (
          <>
            <h6>Relevant Categories</h6>
            <ul>
              {categories.slice(0, 10).map(({ name, path }, index) => {
                function handleClick() {
                  if (handleClose) {
                    handleClose()
                  }
                  navigate(path)
                }
                return (
                  <li key={`cat-${index}`}>
                    <button onClick={handleClick}>{name}</button>
                  </li>
                )
              })}
            </ul>
          </>
        )}
        {products.length > 0 && (
          <>
            <h6>Relevant Products</h6>
            <ul>
              {products.slice(0, 20).map(({ name, path }, index: number) => {
                function handleClick() {
                  if (handleClose) {
                    handleClose()
                  }
                  navigate(path)
                }
                return (
                  <li key={`prod-${index}`}>
                    <button onClick={handleClick}>{name}</button>
                  </li>
                )
              })}
            </ul>
          </>
        )}
      </>
    )
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (handleClose) {
      handleClose()
    }
    if (inputRef && inputRef.current) {
      inputRef.current.blur()
    }
    setFocused(false)
    navigate(`${SEARCH_ROUTE}?q=${encodeURI(searchValue)}`)
  }
  return (
    <>
      <form onSubmit={handleSubmit} action=".">
        <div className={`searchbar${focused ? " focused" : ""}`}>
          <input
            type="search"
            placeholder="Find something special..."
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={searchValue}
            onChange={handleChange}
            autoFocus={Boolean(!isNavbar)}
            ref={inputRef}
            className={isNavbar ? "min-width" : ""}
          />
          <button className="btn flex-justify-center" type="submit">
            <Icon iconName="search" width={20} />
          </button>
        </div>
      </form>
      {isNavbar ? (
        <Grow in={focused}>
          <div className="search-menu navbar-search">
            {renderSearchResults()}
          </div>
        </Grow>
      ) : (
        <div className="search-menu">{renderSearchResults()}</div>
      )}
    </>
  )
}

export default SearchBar
