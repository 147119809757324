import React from "react"
import Dialog from "@material-ui/core/Dialog"
import Icon from "./Icon"
import SearchBar from "./SearchBar"

export default () => {
  const [open, setOpen] = React.useState(false)
  const [isClient, setIsClient] = React.useState(false)
  function handleOpen() {
    setOpen(true)
  }
  function handleClose() {
    setOpen(false)
  }
  React.useEffect(() => {
    setIsClient(true)
  }, [])

  if (isClient) {
    return (
      <>
        <button className="btn search-btn nav-btn" onClick={handleOpen}>
          <Icon iconName="search" width={22} />
        </button>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
          <div className="padding-2 searchbar-dialog">
            <SearchBar handleClose={handleClose} />
          </div>
        </Dialog>
      </>
    )
  }
  return null
}
