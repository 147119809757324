import React from "react"
import Snackbar from "@material-ui/core/Snackbar"
import Icon from "./Icon"

interface LayoutSnackbarProps {
  snackbarMessage: string
  setSnackbarMessage: (message: string) => void
}

const LayoutSnackbar: React.FC<LayoutSnackbarProps> = ({
  snackbarMessage,
  setSnackbarMessage,
}) => {
  const [open, setOpen] = React.useState(false)
  React.useEffect(() => {
    if (snackbarMessage) {
      setOpen(true)
    }
  }, [snackbarMessage])
  function handleClose() {
    setOpen(false)
  }
  function handleExited() {
    setSnackbarMessage("")
  }
  if (typeof window === "undefined") {
    return null
  }
  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      autoHideDuration={4000}
      onClose={handleClose}
      message={snackbarMessage}
      onExited={handleExited}
      action={
        <button
          className="btn snackbar-btn flex-justify-center"
          onClick={handleClose}
        >
          <Icon iconName="close" width={24} />
        </button>
      }
    />
  )
}

export default LayoutSnackbar
