import { VinylColor, StainColor } from "../types"

export const FONT_FAMILY = "Rubik"
export const PRIMARY_COLOR = "#ef6c00"
export const SECONDARY_COLOR = "#893c00"
export const LANDING_SVG_MAIN = "#bf360c"
export const CART = "CART"
export const EMAIL = "shopdzignstudio@gmail.com"
export const HOME_ROUTE = "/home/"
export const ABOUT_US_ROUTE = "/about/"
export const WEDDING_ROUTE = "/weddings/"
export const CONTACT_US_ROUTE = "/contact/"
export const SEARCH_ROUTE = "/search/";
export const SEASONAL_ROUTE = "/seasonal/";
export const CUSTOM_ORDER_REQUEST_ROUTE = "/custom-order-request/"

export const STAIN_COLORS: StainColor[] = [
  { value: "Aged Barrel", imageKey: "agedBarrel" },
  { value: "Barn Red", imageKey: "barnRed" },
  { value: "Cherry", imageKey: "cherry" },
  { value: "Classic Grey", imageKey: "classicGrey" },
  { value: "Colonial Maple", imageKey: "colonialMaple" },
  { value: "Dark Walnut", imageKey: "darkWalnut" },
  { value: "Driftwood", imageKey: "driftwood" },
  { value: "Early American", imageKey: "earlyAmerican" },
  { value: "Ebony", imageKey: "ebony" },
  { value: "English Chestnut", imageKey: "englishChestnut" },
  { value: "Espresso", imageKey: "espresso" },
  { value: "Fruitwood", imageKey: "fruitwood" },
  { value: "Golden Oak", imageKey: "goldenOak" },
  { value: "Golden Pecan", imageKey: "goldenPecan" },
  { value: "Gunstock", imageKey: "gunstock" },
  { value: "Honey", imageKey: "honey" },
  { value: "Ipswich Pine", imageKey: "ipswichPine" },
  { value: "Jacobean", imageKey: "jacobean" },
  { value: "Mocha", imageKey: "mocha" },
  { value: "Natural", imageKey: "natural" },
  { value: "Navy", imageKey: "navy" },
  { value: "Pickled Oak", imageKey: "pickledOak" },
  { value: "Provincial", imageKey: "provincial" },
  { value: "Puritan Pine", imageKey: "puritanPine" },
  { value: "Red Chestnut", imageKey: "redChestnut" },
  { value: "Red Mahogany", imageKey: "redMahogany" },
  { value: "Red Oak", imageKey: "redOak" },
  { value: "Rustic Beige", imageKey: "rusticBeige" },
  { value: "Sedona Red", imageKey: "sedonaRed" },
  { value: "Silvered Grey", imageKey: "silveredGrey" },
  { value: "Simply White", imageKey: "simplyWhite" },
  { value: "Slate", imageKey: "slate" },
  { value: "Special Walnut", imageKey: "specialWalnut" },
  { value: "True Black", imageKey: "trueBlack" },
  { value: "Vintage Blue", imageKey: "vintageBlue" },
  { value: "Weathered Oak", imageKey: "weatheredOak" },
];

export const VINYL_COLORS: VinylColor[] = [
  { value: "Black", hex: "#222222" },
  { value: "White", hex: "#ffffff" },
  { value: "Light Grey", hex: "#c0c2c1" },
  { value: "Grey", hex: "#99a3a2" },
  { value: "Dark Grey", hex: "#4b4d4c" },
  { value: "Silver Metallic", hex: "#bbc5c4" },
  { value: "Copper Metallic", hex: "#b2662a" },
  { value: "Gold Metallic", hex: "#c19334" },
  { value: "Beige", hex: "#e2ce9c" },
  { value: "Cream", hex: "#ecd494" },
  { value: "Nut Brown", hex: "#d26b31" },
  { value: "Light Brown", hex: "#c6995e" },
  { value: "Brown", hex: "#45220a" },
  { value: "Soft Pink", hex: "#f080a1" },
  { value: "Pink", hex: "#e03b6f" },
  { value: "Light Red", hex: "#c52d28" },
  { value: "Red", hex: "#d33349" },
  { value: "Dark Red", hex: "#881c21" },
  { value: "Burgundy", hex: "#6a162a" },
  { value: "Orange", hex: "#ed4534" },
  { value: "Pastel Orange", hex: "#ee5735" },
  { value: "Golden Yellow", hex: "#f5ab41" },
  { value: "Signal Yellow", hex: "#ecb742" },
  { value: "Light Yellow", hex: "#f3db4a" },
  { value: "Brimstone Yellow", hex: "#f2e14b" },
  { value: "Mint", hex: "#85d8be" },
  { value: "Lime Tree Green", hex: "#61af4a" },
  { value: "Yellow Green", hex: "#4ba428" },
  { value: "Green", hex: "#419367" },
  { value: "Dark Green", hex: "#1f4f2f" },
  { value: "Turquoise", hex: "#1d515a" },
  { value: "Turquoise Blue", hex: "#429d98" },
  { value: "Ice Blue", hex: "#41a1b9" },
  { value: "Light Blue", hex: "#3488ac" },
  { value: "Brilliant Blue", hex: "#1630a1" },
  { value: "Traffic Blue", hex: "#033b84" },
  { value: "Gentian", hex: "#073e81" },
  { value: "King Blue", hex: "#13256f" },
  { value: "Dark Blue", hex: "#1c1f4a" },
  { value: "Steel Blue", hex: "#14133d" },
  { value: "Lilac", hex: "#cdb2e2" },
  { value: "Purple", hex: "#6d4383" },
  { value: "Lavender", hex: "#76376c" },
];
